import getQueryValue from '@invitato/helpers/dist/getQueryStringValue';
import { useLocation } from '@reach/router';

import { WEDDING_RESEPSI_TIME } from '../constants';
import { ENABLE_SHIFT_TIME_RECEPTION } from '../constants/feature-flags';

/**
 * custom hooks to return shift time type
 * @return {string}
 */
function useShiftTime() {
  if (!ENABLE_SHIFT_TIME_RECEPTION) return WEDDING_RESEPSI_TIME;

  const location = useLocation();
  const shiftType = getQueryValue(location, 'shift');

  let shift = '11.00 - 13.00 WIB';

  if (shiftType === '1') {
    shift = '11.00 - 12.00 WIB';
  } else if (shiftType === '2') {
    shift = '12.00 - 13.00 WIB';
  }

  return shift;
}

export default useShiftTime;
